<template>
  <v-tooltip
    :top="position === TOP"
    :right="position === RIGHT"
    :left="position === LEFT"
    :bottom="position === BOTTOM"
    :disabled="disabled"
    z-index="1000"
    :max-width="width"
    :min-width="width"
    :color="color"
  >
    <template v-slot:activator="{ on, attrs }">
      <slot :on="on" :attrs="attrs"></slot>
    </template>
    <span :class="contentClass"> {{ name }}</span>
  </v-tooltip>
</template>

<script>
/**
 * Displays tooltip
 */
export default {
  name: "Tooltip",
  /**
   * ---------------- Data Properties ------------------
   */
  data() {
    const [TOP, RIGHT, LEFT, BOTTOM] = ["top", "right", "left", "bottom"];
    return {
      TOP,
      RIGHT,
      LEFT,
      BOTTOM,
    };
  },
  /**
   * ---------------- Props ------------------
   */
  props: {
    /**
     * Name of the tooltip
     */
    name: {
      type: String,
      required: true,
      default: "Tooltip",
    },
    width: { type: [String, Number], default: "" },
    disabled: {
      type: Boolean,
      default: false,
    },
    /**
     * Postion of the tooltip
     * @values top, right, left
     */
    position: {
      type: String,
      default: "top",
    },
    /**
     * Tooltip content class
     * @field name
     */
    contentClass: {
      type: String,
      default: "",
    },
    /**
     * Type of tooltip to be shown to the user
     */
    type: { type: String, default: "default" },
  },
  computed: {
    color() {
      const { colors, type } = this.$appConfig.tooltip;

      switch (this.type) {
        case type.dark:
          return colors.dark;
        
        case type.success:
          return colors.success;

        default:
          return colors.default;
      }
    },
  },
};
</script>
