<template>
  <div class="server-message">
    <snackbar
      @close="closeSnackbar"
      :timeout="1000 * 20"
      :snack-bar-type="snackBarType"
      :snackbar-value="showMessage"
    >
      {{ message }}
    </snackbar>
  </div>
</template>

<script>
import Snackbar from "@/components/shared/Snackbar.vue";

export default {
  name: "ServerMessage",
  /**
  |--------------------------------------------------
  | Props
  |--------------------------------------------------
  */
  props: {
    hasError: { type: Boolean, required: true, default: false },
    doesUserExists: { type: Boolean, required: true, default: false },
    referrerErrorMessage: { type: String, required: true, default: "" },
  },
  /**
  |--------------------------------------------------
  | Components
  |--------------------------------------------------
  */
  components: { Snackbar },
  /**
  |--------------------------------------------------
  | Data Properties
  |--------------------------------------------------
  */
  data() {
    return {
      showMessage: false,
      snackBarType: "info",
      message: '',
    };
  },
  /**
  |--------------------------------------------------
  | Watching properties
  |--------------------------------------------------
  */
  watch: {
    referrerErrorMessage: {
      handler(message) {
        if (message && message?.length > 0 && this.hasError) {
          this.showErrorMessage(message);
        }
      },
      immediate: true,
    },
  },
  /**
  |--------------------------------------------------
  | Methods
  |--------------------------------------------------
  */
  methods: {
    /**
     * Shows error message in snackbar
     */
    showErrorMessage(message) {
      this.message = message;
      this.showMessage = true;
      this.snackBarType = this.$appConfig.snackbar.snackbarTypes.error;
    },
    closeSnackbar() {
      this.showMessage = false;
    },
  }
};
</script>
