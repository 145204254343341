<template>
  <section id="dashboard">
    <server-message
      :has-error="serverHasError"
      :referrer-error-message="referrerErrorMessage"
      :does-user-exists="userExistsInReffererDomain"
    />
    <div id="dashboard-wrapper" class="mt-4 mb-8">
      <v-row>
        <v-col cols="12" lg="9">
          <h1 class="text-h5 secondary--font font-weight-large mt-3 ml-4">User Profile</h1>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" lg="9">
          <user-profile-form />
        </v-col>
      </v-row>
    </div>

    <snackbar
      @close="closeSnackbar"
      :override-default="overrideSnackbar"
      :snackbarValue="!!successMessage || !!errorMessage"
      :snackBarType="!!errorMessage ? 'error' : 'success'"
    >
      <template #body v-if="overrideSnackbar">
        <div class="my-2 mx-2">
          <div>
            <div class="d-flex align-baseline">
              <v-icon class="mr-3" :color="$appConfig.commonColors.success">
                mdi-check-circle-outline
              </v-icon>
            </div>

            <div class="snackbar-message">
              <div class="text--primary subtitle-2 font-weight-bold">
                {{ successMessage }}
              </div>
              <div class="text--primary body-2">
                You will now be required to provide two forms of identification
                when logging in.
              </div>
            </div>
          </div>
        </div>
      </template>
      <div v-else>
        {{ successMessage || errorMessage }}
      </div>
    </snackbar>
  </section>
</template>

<script>
import { mapGetters } from "vuex";
import Snackbar from "@/components/shared/Snackbar.vue";
import UserProfileForm from "@/components/forms/UserProfileForm.vue";
import ServerMessage from "@/components/shared/ServerMessage.vue";

import { getReffererDomain } from "@/utils";

import keycloakMixin from "@/mixins/keycloak.mixin";

/**
 * Application Dashboard
 */
export default {
  name: "Dashboard",
  /**
  |--------------------------------------------------
  | Components
  |--------------------------------------------------
  */
  components: {
    Snackbar,
    UserProfileForm,
    ServerMessage
  },

  mixins: [keycloakMixin],

  /**
  |--------------------------------------------------
  | Data properties
  |--------------------------------------------------
  */
  data() {
    return {
      verifyEmail: false,
      isLoading: false,
      successMessage: "",
      errorMessage: "",
      userExistsInReffererDomain: true,
      overrideSnackbar: false,
      referrerErrorMessage: "",
      serverHasError: false,
    };
  },
  /**
  |--------------------------------------------------
  | Computed properties
  |--------------------------------------------------
  */
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
      isEmailVerified: "auth/isEmailVerified",
    }),
  },
  /**
  |--------------------------------------------------
  | Methods
  |--------------------------------------------------
  */
  methods: {
    /**
     * Closes snackbar
     */
    closeSnackbar() {
      this.successMessage = "";
      this.errorMessage = "";
      this.overrideSnackbar = false;
    },
    /**
     * Show loader
     */
    showLoader(val) {
      this.isLoading = val;
    },
    showErrorMessage(error) {
      this.errorMessage = error?.message;
    },
    /**
     * isCode
     * @description computes the error code
     * @returns {Boolean}
     */
     isCode(query, code) {
      return query?.code === code;
    },

    /**
     * appendRefferer
     * @description Appends the domain of the refferer to the error messaage
     */
     appendRefferer(message = "") {
      const domainNameExists = message.includes("{{domain_name}}");
      return domainNameExists
        ? message.replace("{{domain_name}}", getReffererDomain())
        : message;
    },
    
    /**
     * Determines that a user exists on a cluster or not
     * @returns {Boolean} User exists or not
     */
     showReffererErrorMessage({ query }) {
      this.referrerErrorMessage = query?.message;
      this.serverHasError = !!query?.message;
    },
  },
  /**
  |--------------------------------------------------
  | Mounted lifecycle hook
  |--------------------------------------------------
  */
  async mounted() {
    await this.fetchKeycloakUserDetail();
    await this.showReffererErrorMessage(this.$route);
  },
};
</script>
