import { mapActions, mapMutations } from "vuex";
import { pick } from "@/utils";
import { getUserProfile } from "@/services/users";

import { KEYCLOAK_USER_DETAILS } from "@/constants/app.js";

export default {
  data() {
    return {
      keycloakUser: null,
    };
  },
  methods: {
    ...mapMutations({
      updateUserDetails: "auth/UPDATE_CURRENT_USER_DETAILS",
      setUserDetails: "auth/SET_USER_PROFILE_DETAILS",
    }),
    ...mapActions({
      logoutUser: "auth/logoutUser",
    }),

    /**
     * Fetch details of the currently logged-in Keycloak user.
     * Ensures the access token is refreshed if about to expire
     * and extracts user profile details.
     */
    async fetchKeycloakUserDetail() {
      try {
        // Load the user's profile from Keycloak
        const response = await getUserProfile();
        const user = response.data

        // Map custom attributes to user object
        user.phoneNumber = user?.attributes?.phoneNumber?.[0]
        user.companyName = user?.attributes?.companyName?.[0]

        // Remove unnecessary metadata
        delete user.userProfileMetadata;

        // Extract and update required user details
        this.keycloakUser = pick(user, KEYCLOAK_USER_DETAILS);
        this.updateUserDetails(this.keycloakUser);
        this.setUserDetails(this.keycloakUser);
      } catch (error) {
        console.log('API Error::', error)
      }
    },
  },
  async mounted() {
    await this.fetchKeycloakUserDetail();
  },
};
